import React, { useState, useEffect, ReactNode } from 'react';
import { ListSelectorValue } from '@cerebral-inc/design-system';
import { Question } from 'types/question';
import segment from 'utils/segment';
import { useFeatureFlags } from 'features';
import { getSegmentEvent, getSubmitSegmentEvent } from '../../helpers';
import { iterableIntegrations, segmentIntegrations } from '../../utils';
import RebrandMultiSelector from './RebrandMultiSelector';
import MultiSelectorContent from './MultiSelectorContent';

export interface IMultiSelectorSingleStackProps {
  question: Question;
  submitAnswer: (answer: string, question: Question, skip?: number) => void;
  isSingleSelect?: boolean;
  popUpText?: string;
  popUpComponent?({ isOpen, onClose }: { isOpen: boolean; onClose(): void }): ReactNode;
}

export const MultiSelectorSingleStack = ({
  question,
  submitAnswer,
  isSingleSelect,
  popUpText,
  popUpComponent
}: IMultiSelectorSingleStackProps) => {
  const [selections, setSelections] = useState<ListSelectorValue[]>([]);
  const { name, options } = question;

  const [openPopup, setOpenPopup] = useState(false);
  const { data: flags } = useFeatureFlags();

  const ContentComponent = flags.growth_brand_redesign_v1 ? RebrandMultiSelector : MultiSelectorContent;

  useEffect(() => {
    const segmentName = getSegmentEvent(name);
    if (segmentName) segment.track(segmentName, undefined, segmentIntegrations);
  }, []);

  const handleSelectionClick = (clickedSelection: ListSelectorValue[]) => {
    if (clickedSelection.length === 0) {
      setSelections([]);
    }

    if (clickedSelection.some(item => item.value === 'None')) {
      setSelections(clickedSelection.filter(item => item.value === 'None'));
      return;
    }

    setSelections(clickedSelection.filter(item => item.value !== 'None'));
  };

  const handleSubmit = (selectedSelection?: string) => {
    // selectedSelection -> rebrand yes no question
    const isSelectionValid = selectedSelection && options.find(item => item.name === selectedSelection);
    const finalSelections = isSelectionValid
      ? selectedSelection
      : selections.map(selection => selection.value).join('|');

    submitAnswer(finalSelections, question);
    const eventName = getSubmitSegmentEvent(name);
    if (eventName) segment.track(eventName, { answered: finalSelections }, iterableIntegrations);
  };

  return (
    <ContentComponent
      question={question}
      isSingleSelect={isSingleSelect}
      popUpText={popUpText}
      selections={selections}
      openPopup={openPopup}
      onSubmit={handleSubmit}
      popUpComponent={popUpComponent}
      onOpenPopup={setOpenPopup}
      onSelect={handleSelectionClick}
    />
  );
};
