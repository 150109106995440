import React from 'react';
import CheckBox from '@iconscout/react-unicons/icons/uil-check';
import { NB } from '@cerebral-inc/ui';

const { Box, Text } = NB;

interface CheckboxProps {
  children: React.ReactNode;
  checked: boolean;
  onChange: () => void;
  isReactivation?: boolean;
  brandRedesign?: boolean;
}

export const Checkbox = ({
  children,
  checked,
  onChange,
  isReactivation,
  brandRedesign
}: CheckboxProps): JSX.Element => {
  const getTextColor = () => {
    if (brandRedesign || isReactivation) {
      return '#353A41';
    }

    return '#1C1E21';
  };

  const getBgColor = () => {
    if (checked) {
      return brandRedesign ? '#443962' : '#51459e';
    }

    return '#FFF';
  };

  const getBorderWidth = () => {
    if (brandRedesign) {
      return checked ? 0 : 1;
    }

    return '1.5px';
  };

  const getMarginLeft = () => {
    if (isReactivation) {
      return '0';
    }

    if (brandRedesign) {
      return '24px';
    }

    return '24px;';
  };

  return (
    <Box
      display="flex"
      flexDirection={isReactivation ? 'row' : 'column'}
      alignItems={isReactivation ? 'center' : 'flex-start'}
    >
      <Text onPress={onChange}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="16px"
          h="16px"
          mr={isReactivation ? '8px' : '0'} // Adjust margin only if reactivation
          mb={isReactivation ? '0' : '8px'} // Adjust margin only if not reactivation
          bg={checked ? '#51459E' : '#fff'}
          borderRadius={brandRedesign ? 4 : 5}
          borderStyle="solid"
          borderWidth={getBorderWidth()}
          borderColor={brandRedesign ? '#8B77C4' : '#A7BCFF'}
          position={isReactivation ? 'relative' : 'absolute'}
          top={isReactivation || brandRedesign ? '0' : '4px'}
          left={isReactivation || brandRedesign ? '0' : '4px'}
          background={getBgColor()}
        >
          <CheckBox style={{ display: checked ? 'block' : 'none', marginLeft: '-1px' }} size={16} color="white" />
        </Box>
      </Text>
      <Text
        color={getTextColor()}
        onPress={onChange}
        fontFamily="Poppins"
        fontSize={12}
        lineHeight={20}
        ml={getMarginLeft()}
      >
        {children}
      </Text>
    </Box>
  );
};
