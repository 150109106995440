import styled, { css } from 'styled-components/macro';

export const ProfileHolder = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: 100%;
  background-color: #fcfbfe;
  min-height: 100vh;

  @media (max-width: 991px) {
    padding-top: 50px;
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export const ContentWrapper = styled.div<{ showNav?: boolean }>`
  display: flex;

  @media (max-width: 991px) {
    overflow: visible;
    height: auto;

    ${props =>
      props.showNav &&
      css`
        overflow: hidden;
        height: 100vh;
        padding: 0;
      `}
  }
`;

export const NotificationBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6786ff;
  position: sticky;
  width: 100%;
  left: 0;
  height: 40px;
  top: 0;
  z-index: 1;

  svg {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

export const ReferAFriend = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  text-decoration: underline;
  color: white;
  cursor: pointer;
  text-align: center;
`;

export const GreenBanner = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 16px;
  background: #9cffe7;
  z-index: 10;

  svg {
    cursor: pointer;
  }
`;

export const GreenBannerText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  @media (max-width: 991px) {
    max-width: 309px;
  }
`;

export const ProfileTopMenu = styled.div<{ showBanner?: boolean }>`
  position: sticky;
  display: flex;
  z-index: 10;
  top: ${props => (props.showBanner ? 40 : 0)}px;
  left: 0;
  width: 100%;
  padding: 9px 25px;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  color: $purple;
  background: #f2f5ff;
  box-shadow:
    0px 7px 8px -4px rgba(28, 30, 33, 0.02),
    0px 13px 19px 2px rgba(28, 30, 33, 0.04),
    0px 5px 24px 4px rgba(28, 30, 33, 0.1);
  justify-content: space-between;
  align-items: center;

  @media (min-width: 991px) {
    padding: 0 25px 0 75px;
    height: 80px;
  }
`;

export const ProfileTopMenuV2 = styled.div<{ showBanner?: boolean }>`
  position: sticky;
  display: flex;
  z-index: 10;
  top: ${props => (props.showBanner ? 40 : 0)}px;
  left: 0;
  width: 100%;
  padding: 9px 25px;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  color: $purple;
  background: #ffffff;
  border: 1px solid #ededed;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 991px) {
    padding: 0 25px 0 75px;
    height: 80px;
  }
`;
