import React from 'react';
import CheckBox from '@iconscout/react-unicons/icons/uil-check';
import { Box, Text } from '@cerebral-inc/design-system';

interface CheckboxProps {
  children: React.ReactNode;
  checked: boolean;
  onChange: () => void;
  isReactivation?: boolean;
}

export const RebrandCheckbox = ({ children, checked, onChange, isReactivation }: CheckboxProps): JSX.Element => (
  <Box
    display="flex"
    flexDirection={isReactivation ? 'row' : 'column'}
    alignItems={isReactivation ? 'center' : 'flex-start'}
  >
    <Text onPress={onChange}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={16}
        height={16}
        marginRight={isReactivation ? 8 : 0}
        marginBottom={isReactivation ? 0 : 8}
        background={checked ? 'eggplant900' : '#fff'}
        borderRadius={5}
        borderStyle="solid"
        borderWidth={1.5}
        borderColor="eggplant900"
        position={isReactivation ? 'relative' : 'absolute'}
        top={isReactivation ? 0 : 4}
        left={isReactivation ? 0 : 4}
      >
        <CheckBox style={{ display: checked ? 'block' : 'none', marginLeft: '-1px' }} size={16} color="white" />
      </Box>
    </Text>
    <Text
      color={isReactivation ? '#353A41' : 'warmGray900'}
      onPress={onChange}
      fontFamily="Poppins"
      fontSize={12}
      lineHeight={15}
      marginLeft={isReactivation ? 0 : 34}
    >
      {children}
    </Text>
  </Box>
);
