import React, { ReactNode } from 'react';
import { NB, ChoicePill, theme } from '@cerebral-inc/ui';
import { ListSelectorValue } from '@cerebral-inc/design-system';
import { Question } from 'types/question';
import { OnboardingButton } from '../OnboardingButton';
import { OnboardingMobileBodyWrapper } from '../OnboardingMobileBodyWrapper';

const { Center, Text, Heading, Flex, Box, Button } = NB;

export interface MultiSelectorContentProps {
  selections: ListSelectorValue[];
  openPopup: boolean;
  onSelect: (value: ListSelectorValue[]) => void;
  onOpenPopup: (value: boolean) => void;
  onSubmit: (value?: string) => void;
  question: Question;
  isSingleSelect?: boolean;
  popUpText?: string;
  popUpComponent?({ isOpen, onClose }: { isOpen: boolean; onClose(): void }): ReactNode;
}

const MultiSelectorContent = ({
  question,
  isSingleSelect,
  popUpText,
  selections,
  openPopup,
  onSubmit,
  popUpComponent,
  onOpenPopup,
  onSelect
}: MultiSelectorContentProps) => {
  const { title, options, subscript } = question;

  return (
    <Center>
      <Center w={{ base: '100%', md: '488px' }}>
        <OnboardingMobileBodyWrapper>
          <Flex px={{ base: '16px', md: '0' }} w={{ base: '100%', md: '488px' }}>
            <Center>
              <Heading
                fontSize={{ base: '20px', sm: '22px' }}
                fontWeight="600"
                lineHeight="24px"
                marginBottom="1"
                maxWidth="400px"
                textAlign="center"
                fontFamily="Poppins"
                color="#1c1e21"
              >
                {title}
              </Heading>
              {subscript && (
                <Text
                  textAlign="center"
                  fontFamily="Poppins"
                  fontWeight="400"
                  marginBottom="2"
                  lineHeight="24px"
                  color="#353A41"
                >
                  {subscript}
                </Text>
              )}
              {!isSingleSelect ? (
                <Text
                  fontSize="12px"
                  fontStyle="italic"
                  marginBottom="5"
                  fontFamily="Poppins"
                  fontWeight="400"
                  color="#626874"
                  lineHeight="16px"
                >
                  Select all that apply
                </Text>
              ) : null}
            </Center>
            <Flex direction="column" justify="center" maxWidth="600px" width="full" marginBottom="2">
              {options.map(option => (
                <Box margin="1" key={option.name}>
                  <ChoicePill
                    key={option.name}
                    onSelect={() => onSelect([{ label: option.name, value: option.name }])}
                    choicePillKey={option.name}
                    choicePillType="multiple"
                    selected={selections.some(selection => selection.value === option.name)}
                    labelText={option.name}
                    fullWidth
                  />
                </Box>
              ))}
            </Flex>
            {popUpText ? (
              <Center>
                <Button
                  onPress={() => {
                    onOpenPopup(true);
                  }}
                  variant="teritary"
                >
                  <Text
                    color="c_primary.600"
                    textDecorationLine="underline"
                    fontSize={theme.fontSizes.xs}
                    fontWeight={theme.fontWeights.semibold}
                  >
                    {popUpText}
                  </Text>
                </Button>
                {openPopup && popUpComponent
                  ? popUpComponent({ isOpen: openPopup, onClose: () => onOpenPopup(false) })
                  : null}
              </Center>
            ) : null}
            <Box maxWidth="md" width="full" mt="3" />
          </Flex>
        </OnboardingMobileBodyWrapper>
        <OnboardingButton isDisabled={selections.length === 0} text="Continue" onSubmit={onSubmit} isStickyButton />
      </Center>
    </Center>
  );
};

export default MultiSelectorContent;
